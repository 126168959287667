import React, { useState } from "react";
import {
  Container,
  InputLabel,
  Box,
  FormControl,
  Select,
  MenuItem,
  AlertTitle,
  Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fileReportDownload } from "../../../utils/fileReportDownload";
import { dateToFormat } from "../../../utils/dateParsing";
import es from "date-fns/locale/es";

const REPORTS_TYPE = [
  { name: 'RIPs (Excel)', url: '/bomberos_urrao_xls_export/rips', file: 'RIPs-excel',  version:process.env.REACT_APP_VERSION_2, formatFile:'xls', extension:'.zip' },
  { name: 'RIPs (txt)', url: '/bomberos_urrao_xls_export/rips', file: 'RIPs-txt',  version:process.env.REACT_APP_VERSION_2, formatFile:'txt',extension:'.zip' },
];

export const BomberosUrrao = () => {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [filterType, setFilterType] = useState(REPORTS_TYPE[0]);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState({});

  registerLocale("es", es);

  const handleSubmit = (event) => {
    event.preventDefault();
    requestReport();
  };

  const requestReport = async () => {
    try {
      const params = {
        fromDate: dateToFormat(fromDate, 'YYYY/MM/DD HH:mm'),
        untilDate: dateToFormat(toDate, 'YYYY/MM/DD HH:mm'),
        formatFile:filterType.formatFile
      }
      setShowAlert(false);
      setLoading(true);
      await fileReportDownload(filterType.url, params, `${filterType.file}${filterType.extension || '.xlsx'}`,filterType.version);
      setLoading(false);
    } catch (error) {
      console.log("error -> ", error);
      if (error.response.status === 406) { //range bad
        setAlertInfo({
          title: "Error",
          message: "Rango de fecha excedido",
          action: "Cambia el rango de fechas",
        });
        setShowAlert(true);
      } else if (error.response.status === 400) { // rango no válido
        setAlertInfo({
          title: "Error",
          message: "El rango de fecha no es válido",
          action: "Valida que el rango sea correcto",
        });
        setShowAlert(true);
      } else if (error.response.status === 0) {
        setAlertInfo({
          title: "Timeout",
          message: "Ocurrio un error procesando",
          action: "Intenta reduciendo el rango de fechas",
        });
        setShowAlert(true);
      } else {
        setAlertInfo({
          title: "Error del servidor",
          message: "Ocurrio un error procesando",
          action: "Intenta reduciendo el rango de fechas",
        });
        setShowAlert(true);
      }
      setLoading(false);
    }
  };

  const handleChangeSelect = (event) => {
    event.preventDefault();
    setFilterType(event.target.value);
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {showAlert && (
            <Alert onClose={() => setShowAlert(false) } severity="error">
              <AlertTitle>{alertInfo?.title}</AlertTitle>
              {alertInfo?.message} — <strong>{alertInfo?.action}</strong>
            </Alert>
          )}

          <InputLabel>Desde</InputLabel>
          <div style={{ display: "flex", marginBottom: 10 }}>
            <DatePicker
              dateFormat="yyyy/MM/dd HH:mm"
              selected={fromDate}
              onChange={setFromDate}
              showTimeSelect
              name="fromDate"
              locale="es"
              className="react-datetimepicker-custom"
            />
          </div>

          <InputLabel>Hasta</InputLabel>
          <div style={{ display: "flex" }}>
            <DatePicker
              dateFormat="yyyy/MM/dd HH:mm"
              selected={toDate}
              onChange={setToDate}
              showTimeSelect
              name="toDate"
              locale="es"
              className="react-datetimepicker-custom"
            />
          </div>

          <br />
          <FormControl fullWidth>
            <InputLabel id="filtertype-select-label">Filtrar Por</InputLabel>
            <Select
              labelId="filtertype-select-label"
              id="filtertype-select"
              value={filterType}
              label="filterType"
              onChange={handleChangeSelect}
              name="filtertype-select"
            >
              {
                REPORTS_TYPE.map((item, key) => <MenuItem value={item}>{item.name}</MenuItem> )
              }
            </Select>
          </FormControl>

          <LoadingButton
            type="submit"
            fullWidth
            loading={loading}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Generar Reporte
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
};
