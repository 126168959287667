import React, { useState } from "react";
import {
  Container,
  InputLabel,
  Box,
  FormControl,
  Select,
  MenuItem,
  AlertTitle,
  Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fileReportDownload } from "../../../utils/fileReportDownload";
import { dateToFormat } from "../../../utils/dateParsing";
import es from "date-fns/locale/es";

export const ServirefriaireReport = () => {
  const [fromDate, setFromDate] = useState(new Date());
  const [fromDateReview,setFromDateReview] = useState(new Date());
  const [toDateReview, setToDateReview] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [filterType, setFilterType] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingReview, setLoadingReview] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertReview, setShowAlertReview] = useState(false);
  const [alertInfo, setAlertInfo] = useState({});
  const [alertInfoReview, setAlertInfoReview] = useState({});

  registerLocale("es", es);

  const handleSubmit = (event) => {
    event.preventDefault();
    requestReport();
  };

  const handleReviewsSubmit = (event) => {
    event.preventDefault();
    requestReviewReport();
  };  

  const requestReport = async () => {
    try {
      const params = {
        fromDate: dateToFormat(fromDate, 'YYYY/MM/DD HH:mm'),
        untilDate: dateToFormat(toDate, 'YYYY/MM/DD HH:mm'),
        filterType
      }
      setShowAlert(false);
      setLoading(true);
      await fileReportDownload("/servirefriaire_xls_export", params, "servicios.xlsx");
      setLoading(false);
    } catch (error) {
      console.log("error -> ", error);
      if (error.response.status === 406) { //range bad
        setAlertInfo({
          title: "Error",
          message: "Rango de fecha excedido",
          action: "Cambia el rango de fechas",
        });
        setShowAlert(true);
      } else if (error.response.status === 400) { // rango no válido
        setAlertInfo({
          title: "Error",
          message: "El rango de fecha no es válido",
          action: "Valida que el rango sea correcto",
        });
        setShowAlert(true);
      } else if (error.response.status === 0) {
        setAlertInfo({
          title: "Timeout",
          message: "Ocurrio un error procesando",
          action: "Intenta reduciendo el rango de fechas",
        });
        setShowAlert(true);
      } else {
        setAlertInfo({
          title: "Error del servidor",
          message: "Ocurrio un error procesando",
          action: "Intenta reduciendo el rango de fechas",
        });
        setShowAlert(true);
      }
      setLoading(false);
    }
  };

  const requestReviewReport = async () => {
    try {
      const params = {
        fromDate: dateToFormat(fromDateReview, 'YYYY/MM/DD HH:mm'),
        untilDate: dateToFormat(toDateReview, 'YYYY/MM/DD HH:mm')
      }
      setShowAlertReview(false);
      setLoadingReview(true);
      await fileReportDownload("/servirefriaire_review_report", params, "revisiones.xlsx");
      setLoadingReview(false);
    } catch (error) {
      console.log("error -> ", error);
      if (error.response.status === 406) { //range bad
        setAlertInfoReview({
          title: "Error",
          message: "Rango de fecha excedido",
          action: "Cambia el rango de fechas",
        });
        setShowAlertReview(true);
      } else if (error.response.status === 400) { // rango no válido
        setAlertInfoReview({
          title: "Error",
          message: "El rango de fecha no es válido",
          action: "Valida que el rango sea correcto",
        });
        setShowAlertReview(true);
      } else if (error.response.status === 0) {
        setAlertInfoReview({
          title: "Timeout",
          message: "Ocurrio un error procesando",
          action: "Intenta reduciendo el rango de fechas",
        });
        setShowAlertReview(true);
      } else {
        setAlertInfoReview({
          title: "Error del servidor",
          message: "Ocurrio un error procesando",
          action: "Intenta reduciendo el rango de fechas",
        });
        setShowAlertReview(true);
      }
      setLoadingReview(false);
    }
  };

  const handleChangeSelect = (event) => {
    event.preventDefault();
    setFilterType(event.target.value);
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <h2>Reportes</h2>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {showAlert && (
            <Alert onClose={() => setShowAlert(false) } severity="error">
              <AlertTitle>{alertInfo?.title}</AlertTitle>
              {alertInfo?.message} — <strong>{alertInfo?.action}</strong>
            </Alert>
          )}

          <InputLabel>Desde</InputLabel>
          <div style={{ display: "flex", marginBottom: 10 }}>
            <DatePicker
              dateFormat="yyyy/MM/dd HH:mm"
              selected={fromDate}
              onChange={setFromDate}
              showTimeSelect
              name="fromDate"
              locale="es"
              className="react-datetimepicker-custom"
            />
          </div>

          <InputLabel>Hasta</InputLabel>
          <div style={{ display: "flex" }}>
            <DatePicker
              dateFormat="yyyy/MM/dd HH:mm"
              selected={toDate}
              onChange={setToDate}
              showTimeSelect
              name="toDate"
              locale="es"
              className="react-datetimepicker-custom"
            />
          </div>

          <br />
          <FormControl fullWidth>
            <InputLabel id="filtertype-select-label">Filtrar Por</InputLabel>
            <Select
              labelId="filtertype-select-label"
              id="filtertype-select"
              value={filterType}
              label="filterType"
              onChange={handleChangeSelect}
              name="filtertype-select"
            >
              <MenuItem value={0}>Todos</MenuItem>
              <MenuItem value={1}>Correctivos</MenuItem>
              <MenuItem value={2}>Correctivos Urgentes</MenuItem>
              <MenuItem value={3}>Preventivos: Ductos de extracción</MenuItem>
              <MenuItem value={4}>Preventivos</MenuItem>
            </Select>
          </FormControl>

          <LoadingButton
            type="submit"
            fullWidth
            loading={loading}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Generar Reporte
          </LoadingButton>
        </Box>
      </Box>
      

      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <h2>Revisiones</h2>
        <Box component="form" onSubmit={handleReviewsSubmit} noValidate sx={{ mt: 1 }}>
          {showAlertReview && (
            <Alert onClose={() => setShowAlertReview(false) } severity="error">
              <AlertTitle>{alertInfoReview?.title}</AlertTitle>
              {alertInfoReview?.message} — <strong>{alertInfoReview?.action}</strong>
            </Alert>
          )}

          <InputLabel>Desde</InputLabel>
          <div style={{ display: "flex", marginBottom: 10 }}>
            <DatePicker
              dateFormat="yyyy/MM/dd HH:mm"
              selected={fromDateReview}
              onChange={setFromDateReview}
              showTimeSelect
              name="fromDate"
              locale="es"
              className="react-datetimepicker-custom"
            />
          </div>

          <InputLabel>Hasta</InputLabel>
          <div style={{ display: "flex" }}>
            <DatePicker
              dateFormat="yyyy/MM/dd HH:mm"
              selected={toDateReview}
              onChange={setToDateReview}
              showTimeSelect
              name="toDate"
              locale="es"
              className="react-datetimepicker-custom"
            />
          </div>

          <br />
          <LoadingButton
            type="submit"
            fullWidth
            loading={loadingReview}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Generar Reporte
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
};
