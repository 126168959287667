import axios from "axios";

const getToken = () => {
  return `?token=${localStorage.getItem('_SESSION_REPORT_')}`
}

export const fileReportUpload = (endpoint, formData, version = '/v1') => {
  return new Promise((resolve, reject) => {
    const url = process.env.REACT_APP_API_REPORT_URL + version + endpoint + getToken();
    axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
