import React, { useEffect, useState } from "react";
import {
  Container,
  InputLabel,
  Box,
  TextField,
  AlertTitle,
  Alert,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateToFormat } from "../../../utils/dateParsing";
import es from "date-fns/locale/es";
import { HTTP_VERBS, requestHttp } from "../../../utils/httpClient";

export const MatrixIndicators = () => {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [customer, setCustomer] = useState(null);
  const [resource, setResource] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState({});
  const [showReport, setShowReport] = useState(false);
  const [ttr, setTtr] = useState(null);
  const [ftfr, setFtfr] = useState(null);

  const [openCustomers, setOpenCustomers] = React.useState(false);
  const [customerOptions, setCustomerOptions] = React.useState([]);
  const [findCustomerValue, setFindCustomerValue] = React.useState("");
  const loadingSearchCustomer = openCustomers && customerOptions.length === 0;

  const [openResources, setOpenResources] = React.useState(false);
  const [resourcesOptions, setResourcesOptions] = React.useState([]);
  const [findResourceValue, setFindResourceValue] = React.useState("");
  const loadingSearchResource = openResources && resourcesOptions.length === 0;

  registerLocale("es", es);

  const handleChangeSelectCustomer = (event, newValue) => {
    event.preventDefault();
    setCustomer(newValue);
  };

  const handleChangeSelectResource = (event, newValue) => {
    event.preventDefault();
    setResource(newValue);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    requestReport();
  };

  const requestReport = async () => {
    try {
      const params = {
        fromDate: dateToFormat(fromDate, "YYYY-MM-DD"),
        untilDate: dateToFormat(toDate, "YYYY-MM-DD"),
        idCompany: customer?._id || null,
        idAssignedUser: resource?._id || null,
      };
      setShowAlert(false);
      setLoading(true);
      setShowReport(false);

      const ttrResponse = await requestHttp({
        endpoint: "/matrix_indicators/time_to_repair",
        data: params,
      });
      setTtr(ttrResponse.data);

      const ftfrResponse = await requestHttp({
        endpoint: "/matrix_indicators/first_time_fix_rate",
        data: params,
      });
      setFtfr(ftfrResponse.data);

      setShowReport(true);
      setLoading(false);
    } catch (error) {
      console.log("error -> ", error);
      setAlertInfo({
        title: "Error del servidor",
        message: "Ocurrio un error procesando",
        action: "Intenta cambiando los parámetros del filtro",
      });
      setShowAlert(true);
      setLoading(false);
    }
  };

  const requestCustomersFilter = async (element) => {
    try {
      const response = await requestHttp({
        endpoint: "/matrix_indicators/filters/customers",
        params: {
          search: element,
        },
        method: HTTP_VERBS.GET,
      });
      return response.data?.customers || [];
    } catch (err) {
      console.log("e ->", err);
    }
  };

  const requestResourcesFilter = async (element) => {
    try {
      const response = await requestHttp({
        endpoint: "/matrix_indicators/filters/users",
        params: {
          search: element,
        },
        method: HTTP_VERBS.GET,
      });
      return response.data?.users || [];
    } catch (err) {
      console.log("e ->", err);
    }
  };

  useEffect(() => {
    (async () => {
      const data = await requestCustomersFilter(findCustomerValue);
      setCustomerOptions([...data]);
    })();
    return () => {};
  }, [findCustomerValue]);

  useEffect(() => {
    (async () => {
      const data = await requestResourcesFilter(findResourceValue);
      setResourcesOptions([...data]);
    })();
    return () => {};
  }, [findResourceValue]);

  /*
  useEffect(() => {
    if (!openCustomers) {
      setCustomerOptions([]);
    }
  }, [openCustomers]);

  useEffect(() => {
    if (!openResources) {
      setResourcesOptions([]);
    }
  }, [openCustomers]);
  */

  const ReportContent = () => {
    return (
      <section style={{ display: "flex" }}>
        <Card sx={{ minWidth: 275, margin: "10px" }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              TTR
            </Typography>
            <Typography variant="h5" component="div">
              { ttr?.data?.ttr } %
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275, margin: "10px" }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              FTFR
            </Typography>
            <Typography variant="h5" component="div">
              { ftfr?.data?.ftfr } %
            </Typography>
          </CardContent>
        </Card>
      </section>
    );
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        md={{
          marginTop: 18,
        }}
      >
        <Box component="form" onSubmit={handleSubmit} noValidate md={{ mt: 1 }}>
          {showAlert && (
            <Alert onClose={() => setShowAlert(false)} severity="error">
              <AlertTitle>{alertInfo?.title}</AlertTitle>
              {alertInfo?.message} — <strong>{alertInfo?.action}</strong>
            </Alert>
          )}
          <section style={{ display: "flex" }}>
            <div style={{ marginRight: "10px" }}>
              <InputLabel>Desde</InputLabel>
              <DatePicker
                dateFormat="yyyy/MM/dd"
                selected={fromDate}
                onChange={setFromDate}
                name="fromDate"
                locale="es"
                className="react-datetimepicker-custom"
              />
            </div>
            <div style={{ marginRight: "10px" }}>
              <InputLabel>Hasta</InputLabel>
              <DatePicker
                dateFormat="yyyy/MM/dd"
                selected={toDate}
                onChange={setToDate}
                name="toDate"
                locale="es"
                className="react-datetimepicker-custom"
              />
            </div>
            <div style={{ marginRight: "10px", marginTop: "20px" }}>
              <Autocomplete
                id="customer-field"
                sx={{ width: 300 }}
                open={openCustomers}
                onOpen={() => {
                  setOpenCustomers(true);
                }}
                onClose={() => {
                  setOpenCustomers(false);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                options={customerOptions}
                loading={loadingSearchCustomer}
                value={customer}
                onChange={handleChangeSelectCustomer}
                onInputChange={(event, newInputValue) => {
                  setFindCustomerValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Clientes"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingSearchCustomer ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div style={{ marginRight: "10px", marginTop: "20px" }}>
              <Autocomplete
                id="resource-field"
                sx={{ width: 300 }}
                open={openResources}
                onOpen={() => {
                  setOpenResources(true);
                }}
                onClose={() => {
                  setOpenResources(false);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.profile.name === value.profile.name
                }
                getOptionLabel={(option) => option.profile.name}
                options={resourcesOptions}
                loading={loadingSearchResource}
                value={resource}
                onChange={handleChangeSelectResource}
                onInputChange={(event, newInputValue) => {
                  setFindResourceValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Técnicos"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingSearchResource ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </section>
          <LoadingButton
            type="submit"
            fullWidth
            loading={loading}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Generar Reporte
          </LoadingButton>
        </Box>
        <section>{showReport && ReportContent()}</section>
      </Box>
    </Container>
  );
};
