import axios from "axios";


const getParamsWithToken = (params) => {
  return {
    ...params,
    token: localStorage.getItem('_SESSION_REPORT_')
  }
}

export const fileReportDownload = (endpoint, params = {}, filenamed = "report.xlsx", version = process.env.REACT_APP_VERSION_1, isPDF = false) => {
  return new Promise((resolve, reject) => {
    const baseUrl = isPDF ? process.env.REACT_APP_API_REPORT_PDF_URL :process.env.REACT_APP_API_REPORT_URL;
    axios({
      url: baseUrl + version + endpoint,
      params: getParamsWithToken(params),
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filenamed);
      document.body.appendChild(link);
      link.click();
      resolve(1);
    }).catch(function (error) {
      reject(error);
    });
  });
};
