import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";

const AUTH_STATE = {
  CHECKING: 0,
  VALID: 1,
  FORBIDDEN: 2,
};

export const AppAuthLayout = () => {

  const [authCheck, setAuthCheck] = useState(AUTH_STATE.CHECKING);

  useEffect(() => {
    setTimeout(() => {validateSession()}, 200);
  }, []);

  const validateSession = () => {
    if (localStorage.getItem('_SESSION_REPORT_')) {
      setAuthCheck(AUTH_STATE.VALID);
    } else {
      setAuthCheck(AUTH_STATE.FORBIDDEN);
    }
  };

  if (authCheck === AUTH_STATE.CHECKING) {
    return (
      <Box
        sx={{
          height: 300,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (authCheck === AUTH_STATE.FORBIDDEN) {
    return (
      <Box
        sx={{
          height: 300,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DoDisturbIcon sx={{ color: red[500] }} />
        <Typography variant="body1">Acceso no permitido</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Outlet />
    </Box>
  );
};
