import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Box,
  AlertTitle,
  Alert,
  Button,
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import { fileReportUpload } from "../../../utils/fileReportUpload";

const Input = styled("input")({
  display: "none",
});

const SEVERITY = {
  ERROR: "error",
  SUCCESS: "success",
};

export const ServirefriaireImport = () => {
  const [fileNameSelected, setFileNameSelected] = useState("");
  const [fileSelected, setFileSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState({});
  const [severityAlert, setSeverityAlert] = useState(SEVERITY.ERROR);
  const [dataResult, setDataResult] = useState([]);
  const [showResult, setShowResult] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    requestImport();
  };

  const fileSelectedHandler = (event) => {
    const file = event.target.files[0];
    const fileName = event.target.files[0].name;
    setFileSelected(file);
    setFileNameSelected(fileName);
  };

  const requestImport = async () => {
    try {
      setShowResult(false);
      setShowAlert(false);
      setLoading(true);
      const formData = new FormData();
      formData.append("notes.xlsx", fileSelected);
      const response = await fileReportUpload(
        "/servirefriaire_xls_import_notes",
        formData
      );
      console.log("response", response);
      setDataResult(response.data);
      setSeverityAlert(SEVERITY.SUCCESS);
      setAlertInfo({
        title: "Importación éxitosa",
        message: "Los datos fueron procesados",
        action:
          "Te sugrimos validar que la información este correcta en la plataforma",
      });
      setShowResult(true);
      setShowAlert(true);
      setLoading(false);
    } catch (error) {
      console.log("error -> ", error);
      setSeverityAlert(SEVERITY.ERROR);
      if (error.response.status === 0) {
        setAlertInfo({
          title: "Timeout",
          message: "Ocurrio un error procesando",
          action: "Intenta cargando el archivo nuevamente",
        });
        setShowAlert(true);
      } else {
        setAlertInfo({
          title: "Error del servidor",
          message: "Ocurrio un error procesando",
          action: "Intenta cargando el archivo nuevamente",
        });
        setShowAlert(true);
      }
      setLoading(false);
    }
  };

  const TableResult = (rows) => {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id Servicio</TableCell>
              <TableCell align="right">Nota</TableCell>
              <TableCell align="right">Importado (Si/No)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, key) => (
              <TableRow
                key={key}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">{row.privateId}</TableCell>
                <TableCell align="right">{row.note}</TableCell>
                <TableCell align="right">{row.errors?.service_not_found ? "No": "Si" }</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <Box
          component="form"
          enctype="multipart/form-data"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          {showAlert && (
            <Alert onClose={() => setShowAlert(false)} severity={severityAlert}>
              <AlertTitle>{alertInfo?.title}</AlertTitle>
              {alertInfo?.message} — <strong>{alertInfo?.action}</strong>
            </Alert>
          )}
          <br />
          <br />
          <label htmlFor="contained-button-file">
            <Input
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              id="contained-button-file"
              multiple
              type="file"
              onChange={fileSelectedHandler}
            />
            <Button variant="contained" component="span">
              Seleccione archivo a cargar
            </Button>
          </label>

          <Typography component="p" variant="body1">
            {fileNameSelected}
          </Typography>

          <LoadingButton
            type="submit"
            fullWidth
            loading={loading}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Subir archivo
          </LoadingButton>
        </Box>
        <br />
        {
          showResult && (
            <Fragment>
              <Typography component="div" variant="h6">
                Resumen de importación
              </Typography>
              { TableResult(dataResult) }
            </Fragment>
          )
        }
      </Box>
    </Container>
  );
};
